import { PageProps } from 'gatsby';
import compact from 'lodash/compact';
import { OrderData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Order from '~/components/List/Item/Order';
import CMSView from '~/components/View';
import List from '~/containers/Espace/List';
import commande from '~/params/order.json';
import TemplateEspace from '~/templates/espace';
// import { parse } from '~/utils/queryString';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageBonsCommande: FC<
  PageProps & EspaceProps & UserProps & { pageContext: { tab?: number } }
> = ({
  espace,
  location,
  user,
  pageContext: { tab },
  params: { espaceId },
}) => {
  const { t } = useTranslation();
  // const params = parse(location.search);

  // const [wheres, setWheres] = useState<{ [key: string]: any }>({});

  // const paramsStringPaymentStatus =
  //   params.paymentStatus && Array.isArray(params.paymentStatus)
  //     ? params.paymentStatus.join(',')
  //     : params.paymentStatus;

  // const paramsStringType =
  //   params.type && Array.isArray(params.type)
  //     ? params.type.join(',')
  //     : params.type;

  // useEffect(() => {
  //   setWheres(oldWheres => {
  //     const newWheres: { [key: string]: any } = { ...oldWheres };
  //
  //     if (params.paymentStatus) {
  //       newWheres.paymentStatus = {
  //         operator: 'in',
  //         value: params.paymentStatus,
  //       };
  //     } else {
  //       delete newWheres.paymentStatus;
  //     }
  //
  //     return newWheres;
  //   });
  // }, [paramsStringPaymentStatus]);

  // useEffect(() => {
  //   setWheres(oldWheres => {
  //     const newWheres: { [key: string]: any } = { ...oldWheres };
  //
  //     if (params.type) {
  //       newWheres.type = {
  //         operator: 'in',
  //         value: params.type,
  //       };
  //     } else {
  //       delete newWheres.type;
  //     }
  //
  //     return newWheres;
  //   });
  // }, [paramsStringType]);

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          filters={compact([
            // {
            //   label: 'Paiement',
            //   multiple: true,
            //   name: 'paymentStatus',
            //   options: [
            //     {
            //       label: 'A confirmer',
            //       value: 'requires_capture',
            //     },
            //     {
            //       label: 'En attente',
            //       value: 'requires_payment_method',
            //     },
            //     {
            //       label: 'Validé',
            //       value: 'succeeded',
            //     },
            //     {
            //       label: 'Annulé',
            //       value: 'canceled',
            //     },
            //   ],
            // },
            // {
            //   label: 'Type',
            //   multiple: true,
            //   name: 'type',
            //   options: [
            //     {
            //       label: 'Order',
            //       value: 'order',
            //     },
            //     {
            //       label: 'Panier',
            //       value: 'cart',
            //     },
            //     {
            //       label: 'Devis',
            //       value: 'quotation',
            //     },
            //   ],
            // },
          ])}
          itemList={Order}
          itemPathnamePrefix={`/espaces/${espaceId}/commandes/`}
          model={
            new OrderData({
              espaceId,
              // wheres,
              orderDirection: 'desc',
              orderField: 'createdAt',
              params: commande,
            })
          }
          pathname={location.pathname}
          search={location.search}
          tabIndex={tab}
          title={t('bons-commande.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageBonsCommande);
