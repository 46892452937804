import {
  DocumentType,
  OrderStatusType,
  OrderType,
} from '@innedit/innedit-type';
import { OrderData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';

import capitalize from '../../../utils/capitalize';
import Tag, { TagStateProps } from '../../Tag';
import ListItem from './index';

const labelStatus = (status?: OrderStatusType): string | undefined => {
  let statusLabel;
  switch (status) {
    case 'canceled': {
      statusLabel = 'Bon de commande annulé';
      break;
    }

    case 'confirmed': {
      statusLabel = 'Bon de commande confirmé';
      break;
    }

    case 'draft': {
      statusLabel = 'Brouillon';
      break;
    }

    case 'paid': {
      statusLabel = 'Payé';
      break;
    }

    case 'waiting': {
      statusLabel = 'En attente';
      break;
    }

    default:
      statusLabel = status;
  }

  return statusLabel;
};

const stateStatus = (status?: OrderStatusType): TagStateProps => {
  let state: TagStateProps;
  switch (status) {
    case 'canceled': {
      state = 'info';
      break;
    }

    case 'confirmed': {
      state = 'info';
      break;
    }

    case 'draft': {
      state = 'info';
      break;
    }

    case 'paid': {
      state = 'success';
      break;
    }

    case 'waiting': {
      state = 'warning';
      break;
    }

    default:
      state = 'standard';
  }

  return state;
};

// const statePaymentStatus = (paymentStatus?: string): TagStateProps => {
//   let state: TagStateProps;
//   switch (paymentStatus) {
//     case 'canceled': {
//       state = 'info';
//       break;
//     }
//
//     case 'partial_refunded': {
//       state = 'warning';
//       break;
//     }
//
//     case 'refunded': {
//       state = 'info';
//       break;
//     }
//
//     case 'requires_capture': {
//       state = 'danger';
//       break;
//     }
//
//     case 'succeeded': {
//       state = 'success';
//       break;
//     }
//
//     default:
//       state = 'standard';
//   }
//
//   return state;
// };

const ListItemCommande: FC<ListItemProps<OrderType, OrderData>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<OrderType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  const date = doc.createdAt;

  const name = [];
  if (doc.contactName) {
    name.push(doc.contactName);
  }

  if (doc.contactZip || doc.contactCity) {
    const city = [];
    if (doc.contactZip) {
      city.push(doc.contactZip);
    }
    if (doc.contactCity) {
      city.push(capitalize(doc.contactCity.trim()));
    }
    name.push(`(${city.join(' ')})`);
  }

  const total =
    OrderData.calculateProduitsAmount(doc.products) +
    (doc.deliveryMethod && 'carrier' === doc.deliveryMethod
      ? doc.deliveryAmount ?? 0
      : 0);

  const deliveryAmount = doc.deliveryAmount ?? doc.deliveryEstimation;

  return (
    <ListItem
      date={date}
      description={doc.products?.map(product => product.label).join('/n')}
      displayActionId={false}
      doc={doc}
      index={index}
      label={`${name.join(' ')}`}
      onClick={onClick}
    >
      <div className="flex space-x-1 items-start mt-2">
        <Tag state="info">{`${total} €`}</Tag>
        {doc.status && (
          <Tag state={stateStatus(doc.status)}>{labelStatus(doc.status)}</Tag>
        )}
        {doc.paymentAmountCapturable > 0 && (
          <Tag state="danger">{doc.paymentAmountCapturable} € à valider</Tag>
        )}
        {doc.paymentAmountRefunded > 0 && (
          <Tag state="warning">
            Remboursement de {doc.paymentAmountRefunded} €
          </Tag>
        )}
        {/* {doc.paymentStatus && ( */}
        {/*  <div className="flex space-x-3"> */}
        {/*    <Tag state={statePaymentStatus(doc.paymentStatus)}> */}
        {/*      {labelPaymentStatus(doc.paymentStatus)} */}
        {/*    </Tag> */}
        {/*  </div> */}
        {/* )} */}
        {doc.deliveryMethod && 'store' === doc.deliveryMethod && (
          <Tag>Retrait au magasin</Tag>
        )}
        {'carrier' === doc.deliveryMethod && (
          <>
            {undefined === deliveryAmount && (
              <Tag>Livraison: Erreur montant</Tag>
            )}
            {undefined !== deliveryAmount && 0 > deliveryAmount && (
              <Tag state="danger">Livraison: Estimation impossible</Tag>
            )}
            {undefined !== deliveryAmount && 0 === deliveryAmount && (
              <Tag>Livraison offerte</Tag>
            )}
            {undefined !== deliveryAmount && 0 < deliveryAmount && (
              <Tag>Livraison : {deliveryAmount}€</Tag>
            )}
          </>
        )}
      </div>
    </ListItem>
  );
};

export default ListItemCommande;
